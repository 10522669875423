
const dev = {
  BASE_URL:
    // process.env.NODE_ENV === "development"
    //   ? "http://localhost:8000"
    //   : 
      "https://api.inburg.com",
  IMAGE_URL: 'https://api.inburg.com/',
  RAZORPAY_API_KEY: 'rzp_live_ZsXTptUGMl84M7',
  RAZORPAY_SECRET_KEY: 'NY8tOd9y1glDT91oEabvZcKI'
};

const ENV = dev;

export default ENV;