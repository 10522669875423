import * as types from './actionConstants';

/**
 * Notification Popup
 */
export const showToast = (payload) => ({
  type: types.SHOW_TOAST,
  payload
})

export const setCurrentOffer = (payload) =>({
  type:types.SET_CURRENT_OFFER,
  payload
})
export const resetCurrentOffer = ()=>({
  type: types.RESET_CURRENT_OFFER
})
