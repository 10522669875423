import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

const HomePage = () => {
  const [finishStatus, setfinishStatus] = useState(false);
  const [latiTude, setLatiTude] = useState(null);
  const [longiTude, setLongiTude] = useState(null);
  const history = useHistory();

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!finishStatus) {
      setfinishStatus(true)
      history.push('/');
    }
  }


  // function displayLocation(latitude, longitude) {
  //   var geocoder;
  //   geocoder = new window.google.maps.Geocoder();
  //   var latlng = new window.google.maps.LatLng(latitude, longitude);
  //   geocoder.geocode(
  //     { 'latLng': latlng },
  //     function (results, status) {
  //       console.log(results, 'results')
  //       console.log(status, 'status')
  //       if (status == window.google.maps.GeocoderStatus.OK) {
  //         if (results[0]) {
  //           var add = results[0].formatted_address;
  //           var value = add.split(",");
  //           console.log(value, 'value')
  //           // count = value.length;
  //           // country = value[count - 1];
  //           // state = value[count - 2];
  //           // city = value[count - 3];
  //           // x.innerHTML = "city name is: " + city;
  //         }
  //         else {
  //           console.log('address not found')
  //          // x.innerHTML = "address not found";
  //         }
  //       }
  //       else {
  //         console.log('Geocoder failed due to: ', status)
  //         // x.innerHTML = "Geocoder failed due to: " + status;
  //       }
  //     }
  //   );
  // }

  useEffect(() => {

    // navigator.geolocation.getCurrentPosition(function (position) {
    //   setLatiTude(position.coords.latitude)
    //   setLongiTude(position.coords.longitude)
    //   displayLocation(position.coords.latitude, position.coords.longitude)
    // });

    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };

  }, []);

  return (
    <div className="min-h-screen max-w-[480px] mx-auto flex justify-center items-center bg-black text-white">
      <div className="flex justify-center it flex-col items-center p-[20px] leading-8">
        <img className="w-[230px]" src="/image/Logo.jpg" />
        <h1 className="text-[22px] font-bold text-center p-4 text-[#D6EFC5]">
          Where Rewards Make Sense
        </h1>

        <div className="gap-3 text-center mt-5">
          <h1>Inburg Supports</h1>
          <div className="flex mt-5 gap-5">
            <img className="w-[70px]" src="/image/Paytm.png" />
            <img className="w-[40px]" src="/image/phonepe 1.png" />
            <img className="w-[40px]" src="/image/phonepe 2.png" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
