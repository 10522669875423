import { RESET_CURRENT_OFFER, SET_CURRENT_OFFER } from "../action/actionConstants";

const initialState = {
    transaction_id:null,
    offer_id:null,
    mobile:null,
};

const CurrentOffer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_OFFER:
      for (const [key, value] of Object.entries(action.payload)) {
          state[key] = value;
      }
    return state;
    case RESET_CURRENT_OFFER:
      return {
        transaction_id:null,
        offer_id:null,
        mobile:null,
      }
    default:
      return state;
  }
};

export default CurrentOffer;
