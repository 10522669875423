import React, { useEffect, useState } from "react";
import queryString from 'query-string';
import { useHistory } from "react-router-dom";
import { postReq } from "@utils/ApiHandler";
import { useSelector, useDispatch } from "react-redux";
import { resetCurrentOffer, showToast } from "@redux/action";
import { MobileValidation } from "@utils/Auth";
import { parseYupError } from "@utils/Yup";
import { isYupError } from "@utils/Yup";
import ENV from "@utils/env";

import Loader from "../components/Loader";

export const CouponCode = () => {
  const [merchantId, setMerchantId] = useState()
  const [paymentId, setPaymentId] = useState()

  const [errors, setErrors] = useState()
  const [offerId, setOfferId] = useState(null);
  const { transaction_id } = useSelector((state) => state.currentOffer);
  const [mobileNumber, setMobileNumber] = useState();
  const [couponCode, setCouponCode] = useState(false);
  const [couponData, setCouponData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [finishStatus, setfinishStatus] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  let payment_id = paymentId

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    setfinishStatus(true)
    resetCouponCode()
  }

  useEffect(() => {
    let queries = queryString.parse(window.location.search);
    setOfferId(queries['offer_id']);
    setPaymentId(queries['payment_id']);
    setMerchantId(queries['merchant_id']);

    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };

  }, []);

  const handleChange = (e) => {

    /*put validation for mobile number*/
    if (e.target.name === 'mobile_number') {
      setMobileNumber(e.target.value);
      setErrors({
        errors: ""

      })
    }
  }
  const generateCode = async () => {

    let data = {
      transaction_id,
      offer_id: offerId,
      mobile: `+91${mobileNumber}`,
      payment_id: payment_id,
    }
    try {
      setErrors({});
      await MobileValidation.validate({ mobileNumber: mobileNumber }, {
        abortEarly: false,
      });
      setIsLoading(true);
      postReq('/user/user-earn-coupon-code', data).then((res) => {
        // console.log(res?.data?.data[0], 'res?.data?.data')
        if (res.status) {
          if (res.data.data) {
            setIsLoading(false);
            setCouponData(res?.data?.data[0])
            setCouponCode(res?.data?.data[0]);
          } else {
            dispatch(showToast({ type: 'error', message: res.data.error }));
            setIsLoading(false)
          }
        }
      }).catch((e) => {
        setIsLoading(true);
        console.log("error", e);
      });
    }
    catch (error) {
      console.log(parseYupError(error))
      if (isYupError(error)) {
        let tempError = parseYupError(error)
        setErrors(tempError?.separator);
      } else {
        console.log("error");
      }
    }
  }
  const resetCouponCode = () => {
    dispatch(resetCurrentOffer());
    history.push('/');
  }

  const handleBack = () => {
    history.goBack()
  }
  return (
    <>
      <Loader isLoading={isLoading} />
      <section className="flex flex-col min-h-screen max-w-[480px] mx-auto text-black  bg-black gap-0 px-5 ">
        {(couponCode === false) &&
          <div className="flex flex-row items-center py-3 text-white">
            {/* <IoIosArrowBack className="" onClick={handleBack} /> */}
            <div className="text-[#c2c1c5] mx-auto">Confirm</div>
          </div>
        }

        {
          (couponCode === false) && <div className="w-9/12 text-center mx-auto flex flex-col gap-24 m-auto">
            <div className="text-white">
              <p className="text-[20px] font-light">
                Enter contact details <br />
                to recieve your
              </p>
              <h1 className="text-[36px] font-medium text-[#F7991C]">
                coupon code
              </h1>
            </div>
            <div>
              <div className="flex">
                <select
                  className="bg-transparent w-[100px] placeholder:text-white placeholder:text-[18px] placeholder:font-light text-white border-0 border-b-[1px] border-b-slate-700 focus:border-b-sya focus:outline-none focus:ring-0  p-2 px-0 mb-3"
                  name="countrycode"
                  id="countrycode"
                >
                  <option className="bg-black text-white" value="+91">
                    +91
                  </option>
                </select>
                <input
                  type="text"
                  id="company"
                  className="bg-transparent placeholder:text-white placeholder:text-[18px] placeholder:font-light text-white border-0 border-b-[1px] border-b-slate-700 focus:border-b-sya focus:outline-none focus:ring-0 w-full p-2 px-0 mb-3"
                  placeholder="Mobile"
                  required
                  name="mobile_number"
                  value={mobileNumber}
                  error={errors?.mobileNumber}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              {errors && (
                <span className="text-red-600 text-[14px]">
                  {errors.mobileNumber}
                </span>
              )}
            </div>
            <button
              type="button"
              className="text-white text-base w-full rounded-3xl py-[13px] bg-[#222222] hover:bg-[#222222]/90 "
              onClick={(e) => generateCode()}
            >
              Send
            </button>
          </div>
        }
        {
          (couponCode !== false) &&

          // <div className="w-9/12 text-center mx-auto flex flex-col gap-24">
          //   <div className="text-white">
          //     <p className="text-[20px] font-light">
          //       Congratulations!! <br />
          //       You have successfully generated coupon code -
          //     </p>
          //     <h1 className="text-[36px] font-medium text-[#D6EFC5]">
          //       {couponCode}
          //     </h1>
          //     <button onClick={(e) => resetCouponCode(e)} className="text-white text-base w-full rounded-3xl py-[13px] bg-[#222222] hover:bg-[#222222]/90 ">Go Back to Home Page</button>
          //   </div>
          // </div>

          <section className="flex flex-col justify-center items-center min-h-screen max-w-[480px] mx-auto text-black  bg-black gap-0 px-5">
            <div className="w-[252px]">
              <img
                className="w-full h-full object-cover mx-auto"
                src={process.env.PUBLIC_URL + "/page9/successfully.png"}
              />
            </div>

            <div className="flex flex-col justify-center items-center text-center mx-auto text-white">
              <p className="text-xl font-light">
                <span className="font-normal italic">Congratulations!</span> <br />
                Your coupon code for
                <span className="block text-3xl font-normal text-[#F7991C] my-3">
                  {(couponData?.offer_type === 'FLAT') ?
                    '₹' : ''}<span className="text-5xl">{couponData.offer_discount}</span> {(couponData?.offer_type === 'FLAT') ?
                      '' : '% OFF'}
                </span>

              </p>
              <p className="text-[20px]">from</p>
              <div className="w-[100px]">

                <img className="w-full h-full" src={couponData?.merchant_logo ? `${ENV.IMAGE_URL}merchant_logo/${couponData?.merchant_logo}` : '/image/Logo.jpg'} />
              </div>
              <p className="newfont">{couponData.merchant_name}</p>

              <p className="text-[20px] font-light">
                has been successfully sent
                <br /> to your mobile number.
              </p>
              <p className="py-1 text-[#F7991C] text-[25px] font-normal">{couponData.coupon_name}</p>

              <button onClick={(e) => resetCouponCode(e)} className="text-white text-base w-full rounded-3xl py-[13px] mt-3 bg-[#222222] hover:bg-[#222222]/90 ">Go Back to Home Page</button>
            </div>
          </section>
        }
      </section>
    </>
  );
};
export default CouponCode;
