import { combineReducers } from 'redux';
import ToastReducer from "./reducers/ToastReducer";
import CurrentOffer from './reducers/CurrentOffer';

const rootReducer = combineReducers({
  toastReducer: ToastReducer,
  currentOffer: CurrentOffer  
});

export default rootReducer;

