import React, { useEffect, useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { getReq } from "@utils/ApiHandler";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCurrentOffer, showToast } from "@redux/action";
import ENV from "@utils/env";

export const Reward = () => {
  const history = useHistory();
  const { chooseCategory } = useParams();

  let searchQuery = window.location.search;
  let params = new URLSearchParams(searchQuery);
  let merchantId = params.get('merchant_id');
  let paymentId = params.get('payment_id');

  const [allCouponDetails, setAllCouponDetails] = useState([]);
  const [currentOfferId, setCurrentOfferId] = useState(null);
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  let merchant_id = merchantId;

  useEffect(() => {
    if (currentOfferId) {
      dispatch(setCurrentOffer({ offer_id: currentOfferId }));
      history.push(`/couponcode/?offer_id=${currentOfferId}&merchant_id=${merchantId}&payment_id=${paymentId}`);
    }
  }, [currentOfferId]);

  const getAllCouponsDetails = () => {
    getReq(
      `/user/user-coupon-category?category_name=${chooseCategory}&merchant_id=${merchant_id}&search=${search}`
    ).then((res) => {
      if (res.status) {
        setAllCouponDetails(res.data.data);
      } else {
        dispatch(showToast({ type: "error", message: res.error }));
      }
    });
  };

  const handleBack = () => {
    history.goBack();
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setSearch(value);
  };

  useEffect(() => {
    getAllCouponsDetails();
  }, [search]);

  return (
    <section className="flex flex-col max-w-[480px] w-full min-h-screen mx-auto text-black pb-[30px] bg-black gap-[30px] px-3">
      <div className="flex justify-between items-center text-white py-4">
        <IoIosArrowBack
          className="cursor-pointer text-2xl"
          onClick={handleBack}
        />
        <div className="font-light">Reward</div>
        <img src="/image/three.png" />
      </div>

      {/* <div className=" p-4 ">
        <div className="tablet:mr-2 max-w-[400px] m-auto h-[40px] relative ">
          <div className="bg-red w-[20px] absolute top-3 left-2">
            <span className="text-xl">{icon.search}</span>
          </div>
          <input
            className="w-full h-full focus:border-gray-300 focus:ring-gray-400 pl-[40px]"
            type="text"
            name="umid"
            placeholder="Search brands"
            onChange={handleChange}
          />
        </div>
      </div> */}

      {allCouponDetails?.length > 0 ? (
        allCouponDetails?.map((item, index) => {
          return (
            <div
              className="grid place-items-center space-y-6"
              onClick={(e) => setCurrentOfferId(item?.offer_id)}
            >
              <div
                className={
                  item.offer_type === "FLAT"
                    ? "bg-[#D6EFC5] flex justify-between items-center rounded-[30px] p-5 mx-7 min-h-[137px] w-full max-w-[390px] cursor-pointer"
                    : "bg-[#F8AA97] flex justify-between items-center rounded-[30px] p-5 mx-7 min-h-[137px] w-full max-w-[390px] cursor-pointer"
                }
              >
                <div className="font-semiboldflex flex-col justify-center items-center">
                  <div className="flex items-center">
                    <span className="text-4xl self-end">
                      {" "}
                      {item.offer_type === "FLAT" ? "₹" : ""}
                    </span>
                    <span className="text-5xl">
                      {item.offer_type === "FLAT"
                        ? item.offer_discount
                        : item.offer_discount + "%"}
                    </span>
                    <span className="rotate rotate-180 font-normal text-[20px]">
                      {item.offer_type === "FLAT" ? "OFF" : ""}
                    </span>
                    <span className="text-3xl">
                      {item.offer_type === "PERCENT" ? "OFF" : ""}
                    </span>
                  </div>
                  {item.offer_type === "PERCENT" ? (
                    <div className="max-w-[140px] text-center">
                      <span className="text-[13px] font-normal  ">
                        on purchase of ₹{item?.offer_min_amount} and above
                      </span>
                    </div>
                  ) : (
                    <div></div>
                  )}
                  {item.offer_type === "FLAT" ? (
                    <p className="text-[13px] font-normal text-center max-w-[100px]">
                      on purchase of ₹{item?.offer_min_amount} - ₹
                      {item?.offer_max_amount}{" "}
                    </p>
                  ) : (
                    <p></p>
                  )}

                  <p className="text-[13px] pt-3">
                    (Valid till{" "}
                    {new Date(item?.offer_expiry).toString().substring(4, 15)})
                  </p>
                  {/* item?.offer_expiry */}
                </div>

                <div className="flex flex-col max-w-[150px] w-full  justify-center border-l-2 border-black">
                  <div className="m-auto max-w-[100px] w-full h-[100px]">
                    <img
                      alt={item?.merchant_name || "Inburg"}
                      src={
                        item?.merchant_logo
                          ? `${ENV.IMAGE_URL}merchant_logo/${item?.merchant_logo}`
                          : "/image/Logo.jpg"
                      }
                      className="w-full h-full object-contain"
                    />
                  </div>
                  <p className="font-bold text-sm max-w-[100px] w-full m-auto text-center">
                    {item.merchant_name}
                  </p>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div>
          <p className="text-white text-md text-center">No coupon found</p>
        </div>
      )}
    </section>
  );
};
export default Reward;
