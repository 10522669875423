import React, { useEffect, useState } from "react";
import { getReq, postReq } from "@utils/ApiHandler";
import { setCurrentOffer, showToast } from "@redux/action";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import hmacSHA512 from "crypto-js/hmac-sha256";

import ENV from "@utils/env";
import Cookies from "js-cookie";

const Orderconfirmation = () => {
  const [umid, setUmid] = useState(null);
  const [brandDetails, setBrandDetails] = useState({});
  const [payingAmount, setPayingAmount] = useState();
  const [orderDetail, setOrderDetail] = useState({});
  // const [transferId, setTransferId] = useState(null);
  const [coupon, setCoupon] = useState("");
  const [couponFlag, setCouponFlag] = useState(false);
  const [netAmount, setNetAmount] = useState("");

  const [user, setUser] = useState({
    merchant_id: null,
    user_coupon_type: "FIRST_PAYMENT",
    user_coupon_name: null,
    user_redeemed_coupon: 0,
    user_amount: null,
    user_amount_discount: null,
  });

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    let queries = queryString.parse(window.location.search);

    setUmid(queries["umid"]);
  }, []);

  const handlechange = (event) => {
    if (event.target.name === "user_amount") {
      /*Needs to handle condition if coupon code exists already*/
      setUser({
        ...user,
        [event.target.name]: event.target.value,
        user_amount_discount: event.target.value,
      });
    }
    /*Needs to handle coupon code condition*/
    setNetAmount(event.target.value);
  };

  const couponHandlechange = (event) => {
    setCoupon({ [event.target.name]: event.target.value });
  };

  useEffect(() => {
    if (umid) {
      getReq(
        `/user/get-merchant-detail-merchant_umid?merchant_umid=${umid}`
      ).then((res) => {
        if (res.status) {
          if (res?.data?.data) {
            setBrandDetails(res?.data?.data[0]);
            setUser({ ...user, merchant_id: res?.data?.data[0]?.merchant_id });
          } else {
            console.log("......");
            dispatch(showToast({ type: "error", message: res.data.error }));
          }
        }
      });
    }
  }, [umid]);

  const handleClick = () => {
    postReq(`/user/user-amount-payment`, user)
      .then((res) => {
        if (res.status) {
          if (res.data.data) {
            dispatch(setCurrentOffer({ transaction_id: res?.data?.data?.id }));
            history.push("/coupon");
          } else {
            dispatch(showToast({ type: "error", message: res.data.error }));
          }
        } else {
          dispatch(showToast({ type: "error", message: res.error }));
        }
      })
      .catch((e) => {
        console.log("error", e);
      });
  };
  const handleSubmit = () => {
    // const orderId = 'ORDERID_' + new Date().getTime()
    // postReq(`/paynow`, { amount: user.user_amount, orderId: orderId })
    //   .then((res) => {
    //     console.log(res)
    //     if (res.status) {
    //       checkPaymentStatus(res.data.body, orderId)
    //     } else {
    //       dispatch(showToast({ type: "error", message: res.error }));
    //     }
    //   })
    //   .catch((e) => {
    //     console.log("error", e);
    //   });

    if (user.user_amount) {
      let data = {
        amount: user.user_amount,
        merchant_id: brandDetails.merchant_id,
        coupon_code: coupon.coupon_code,
        net_amount: netAmount,
      };
      // console.log(data, 'data ')
      postReq(`/user/transfer-order`, data)
        .then((res) => {
          if (res.status) {
            if (res?.data?.data) {
              setOrderDetail(res?.data?.data);
              payNowPayment(
                res?.data?.data?.order_id,
                res?.data?.data?.transfer_id
              );
            } else {
              dispatch(
                showToast({
                  type: "error",
                  message: res.data.error.error.description,
                })
              );
            }
          } else {
            dispatch(showToast({ type: "error", message: res.error }));
          }
        })
        .catch((e) => {
          console.log("error", e);
        });
    } else {
      dispatch(
        showToast({ type: "error", message: "please enter your amount" })
      );
    }
  };

  const payNowPayment = (id, transferId) => {
    let options = {
      key: ENV.RAZORPAY_API_KEY, // Enter the Key ID generated from the Dashboard
      amount: user.user_amount * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: "INR",
      name: brandDetails?.merchant_name,
      order_id: id, // This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      image: `${ENV.IMAGE_URL}merchant_logo/${brandDetails?.merchant_logo}`,
      handler: function (response) {
        checkPaymentStatus(response, transferId);
        // setTransferId(transferId);
      },
      prefill: {
        name: brandDetails.merchant_name,
        email: brandDetails.merchant_email,
        contact: brandDetails.merchant_contact,
      },
      readonly: { email: true, contact: true },
      config: {
        display: {
          hide: [
            { method: "emi" },
            { method: "cardless_emi" },
            { method: "paylater" },
            { method: "netbanking" },
            { method: "card" },
            { method: "wallet" },
          ],
        },
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#000000",
      },
    };

    // console.log(options, 'options')
    let rzp = new window.Razorpay(options);
    rzp.open();
  };

  const checkPaymentStatus = (response, transferId) => {
    // console.log(response, 'response')
    let payment_id = response.razorpay_payment_id;
    Cookies.set("payment-id", payment_id);
    Cookies.set("merchant-id", brandDetails.merchant_id);
    postReq(`/user/payment-status`, {
      merchant_id: brandDetails.merchant_id,
      razorpay_payment_id: response.razorpay_payment_id,
      razorpay_order_id: response.razorpay_order_id,
      razorpay_signature: response.razorpay_signature,
      transfer_id: transferId,
    })
      .then((res) => {
        if (res.status) {
          if (res?.data?.data) {
            history.push(`/coupon/?merchant_id=${brandDetails.merchant_id}&payment_id=${payment_id}`);
          } else {
            dispatch(showToast({ type: "error", message: res.data.error }));
          }
        } else {
          dispatch(showToast({ type: "error", message: res.error }));
        }
      })
      .catch((e) => {
        console.log("error", e);
      });
  };

  const applyCouponCode = () => {
    if (coupon.coupon_code && user.user_amount) {
      const data = {
        coupon_code: coupon.coupon_code,
        user_amount: user.user_amount,
        merchant_id: brandDetails.merchant_id,
      };
      postReq(`/user/user-use-coupon-code`, data)
        .then((res) => {
          if (res.status) {
            if (res?.data?.data) {
              setUser({ user_amount: res.data.data.total_amount });
              setCouponFlag(true);
            } else {
              dispatch(showToast({ type: "error", message: res.data.error }));
            }
          } else {
            dispatch(showToast({ type: "error", message: res.error }));
          }
        })
        .catch((e) => {
          console.log("error", e);
        });
    } else {
      dispatch(
        showToast({ type: "error", message: "Please enter coupon and amount" })
      );
    }
  };

  // const checkPaymentStatus = (data, orderId) => {
  //   postReq(`/check-pay`, { txnToken: data.txnToken, orderId: orderId })
  //     .then((res) => {
  //       if (res.status) {
  //         if (res.data?.body?.deepLinkInfo?.deepLink)
  //           window.open(res.data?.body?.deepLinkInfo?.deepLink)
  //         else
  //           dispatch(showToast({ type: "error", message: 'something went wrong please try again paytm' }));
  //       }
  //     })
  //     .catch((e) => {
  //       console.log("error", e);
  //     });
  // }

  return (
    <div className="relative min-h-screen flex flex-col justify-between max-w-[480px] mx-auto">
      <div className="flex justify-center text-[#000000] ">
        <p className="text-[17px] py-4 font-light"> You are paying to</p>
      </div>

      <div className="w-full flex items-center flex-col px-4">
        <img
          className="max-h-24"
          src={
            brandDetails?.merchant_logo
              ? `${ENV.IMAGE_URL}merchant_logo/${brandDetails?.merchant_logo}`
              : "/image/Logo.jpg"
          }
        />
        <p className="mt-3 text-center font-semibold text-[20px] text-[#000000]">
          {brandDetails?.merchant_name}
        </p>
        <div className="text-[52px] w-[85%] font-light flex items-center justify-end">
          <div className="m-auto flex justify-center items-center">
            <span className="text-[45px] font-medium ">&#8377;</span>

            {couponFlag === false ? (
              <input
                type="number"
                className="appearance-none hover:appearance-none border-none text-[52px] font-medium max-w-[150px]  focus:border-none focus:outline-none focus:ring-0"
                placeholder="0.00"
                name="user_amount"
                onChange={(e) => handlechange(e)}
              />
            ) : (
              <input
                type="number"
                className="appearance-none hover:appearance-none border-none text-[52px] font-medium w-full max-w-[150px] focus:border-none focus:outline-none focus:ring-0"
                name="user_amount"
                onChange={(e) => handlechange(e)}
                readOnly
              />
            )}
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col justify-between items-center">
        <div className="bg-black text-white w-full grid place-items-center rounded-t-[50px] py-16 px-6 overflow-hidden">
          {Object.values(orderDetail).length > 0 ? (
            ""
          ) : (
            <>
              {couponFlag === false ? (
                <>
                  <p className="font-light text-[20px]">Enter coupon code</p>
                  <input
                    type="text"
                    name="coupon_code"
                    onChange={(e) => couponHandlechange(e)}
                    className="bg-transparent text-slate-300 border-0 border-b-[1px] border-b-slate-700 focus:border-b-sya w-6/12 focus:outline-none focus:ring-0 p-2 text-center mb-3"
                    placeholder=""
                    required
                  />

                  {coupon === "" ? (
                    ""
                  ) : (
                    <button
                      type="button"
                      className="text-white text-base px-[50px] mt-3 rounded-2xl py-[9px] bg-[#222222] hover:bg-[#222222]/90 "
                      onClick={applyCouponCode}
                    >
                      Apply
                    </button>
                  )}
                </>
              ) : (
                ""
              )}
            </>
          )}
          <p className="text-center font-light text-[20px] mt-4">You Pay</p>
          <div className="flex justify-center items-center overflow-hidden">
            <span className="text-[50px] mr-2">&#8377;</span>
            <p className="text-[52px] font-medium max-w-[150px] flex justify-center text-end w-full overflow-hidden">
              {user?.user_amount ? user?.user_amount : 0.0}
            </p>
          </div>

          {Object.values(orderDetail).length > 0 ? (
            ""
          ) : (
            <button
              type="button"
              className="text-white text-base px-[89px] mt-6 rounded-3xl py-[13px] bg-[#222222] hover:bg-[#222222]/90 "
              onClick={handleSubmit}
            >
              Pay
            </button>
          )}

          {/* {(Object.values(orderDetail).length > 0) ?
            <button
              type="button"
              className="text-white text-base px-[89px] mt-6 rounded-3xl py-[13px] bg-[#222222] hover:bg-[#222222]/90 "
              onClick={payNowPayment}>
              Pay
            </button> : ''
          } */}
        </div>
      </div>
    </div>
  );
};

export default Orderconfirmation;
